import React from "react";

import { useSiteMetadata } from "@/hooks";

import { Author } from "./Author";
import { Contacts } from "./Contacts";
import { Copyright } from "./Copyright";
import { Menu } from "./Menu";

import * as styles from "./Sidebar.module.scss";

type Props = {
  isIndex?: boolean;
};

const Sidebar = ({ isIndex }: Props) => {
  const { author, copyright, menu } = useSiteMetadata();

  return (
   <div className={styles.sidebar}>
   
    
    </div>
  );
};

export default Sidebar;

     /* div className={styles.inner}
        Author author={author} isIndex={isIndex}
        Menu menu={menu} />
        Contacts contacts={author.contacts}
        Copyright copyright={copyright} 
        
          </div> */