import React from "react";

import { Link } from "gatsby";

import { Edge } from "@/types";

import * as styles from "./Feed.module.scss";

type Props = {
  edges: Array<Edge>;
};

const Feed: React.FC<Props> = ({ edges }: Props) => (
  <div className={styles.wrapper}>
  <div className={styles.feed}>
    {edges.map((edge) => (

      <div className={styles.item} key={edge.node.fields.slug}>


        <div className={styles.meta}>
       
          <time
            className={styles.time}
            dateTime={new Date(edge.node.frontmatter.date).toLocaleDateString(
              "en-US",
              { year: "numeric", month: "short", day: "numeric" },
            )}
          >
            {new Date(edge.node.frontmatter.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
            })}
          </time>
          <span className={styles.divider} />
          <span className={styles.category}>
            <Link to={edge.node.fields.categorySlug} className={styles.link}>
              {edge.node.frontmatter.category}
            </Link>
          </span>
        </div>


       <div className={styles.emptyWrap}>
          <div className={styles.e1}></div>
          <div className={styles.e2}></div>
        </div> 

        <Link
        className={styles.link}
        to={edge.node.frontmatter?.slug || edge.node.fields.slug}
        >
              <h2 className={styles.title}>
                <div className={styles.linkWrap}>
                
                    <div className={styles.emoji}>
                    <span className={styles.emojiTxt}>{edge.node.frontmatter.emoji}</span>
                    
                    </div>

                    
                    
                    &nbsp;

                    {edge.node.frontmatter.title}
                  
              
                </div>
              </h2>
        </Link>


     





        <p className={styles.description}>
          {edge.node.frontmatter.description}
        </p>






        <Link
          className={styles.more}
          to={edge.node.frontmatter?.slug || edge.node.fields.slug}
        >
        </Link>
      </div>
   
    ))}
  </div>


  <div className={styles.emptyWrap1}>
          <div className={styles.e1_1}></div>
          <div className={styles.e2_2}></div>
  </div> 

  </div>
);

export default Feed;
